import {type ThemeConfig} from 'antd/lib'
import {getCssProperty} from 'lib/helpers/getCssProperty'

////////////////////////////////////////////////////////////////////////////////

export const getDarkThemeConfig = (): ThemeConfig => {
  return {
    components: {
      Table: {
        colorFillSecondary: getCssProperty('--surface-bg-color'),
        colorFillAlter: getCssProperty('--surface-bg-color'),
        colorBorderSecondary: getCssProperty('--border-table'),
        headerSortHoverBg: getCssProperty('--table-header-hover-bg-color'),
        colorBgContainer: getCssProperty('--surface-bg-color'),
        headerBg: getCssProperty('--table-header-bg-color'),
        headerColor: getCssProperty('--table-header-text-color'),
        headerSortActiveBg: getCssProperty('--table-header-bg-color'),
        colorText: getCssProperty('--text-color-muted'),
      },
      Menu: {
        itemHoverColor: getCssProperty('--primary-color-light'),
        itemHoverBg: getCssProperty('--bg-color-secondary'),
        itemSelectedBg: getCssProperty('--bg-color-secondary'),
        itemActiveBg: getCssProperty('--bg-color-secondary'),
        subMenuItemBg: getCssProperty('--submenu-bg-color'),
        itemBg: getCssProperty('--surface-bg-color'),
        itemSelectedColor: getCssProperty('--primary-color-light'),
        colorPrimaryBorder: getCssProperty('--border'),
        itemColor: getCssProperty('--text-color-muted'),
        popupBg: getCssProperty('--bg-color'),
      },
      Layout: {
        siderBg: getCssProperty('--surface-bg-color'),
        bodyBg: getCssProperty('--bg-color'),
        headerBg: getCssProperty('--surface-bg-color'),
        headerPadding: '0',
        headerHeight: 65,
        colorText: getCssProperty('--text-color-muted'),
      },
      Card: {
        colorBorderSecondary: getCssProperty('--border-card'),
        headerHeight: 0,
        colorBgContainer: getCssProperty('--surface-bg-color'),
      },
      Select: {
        colorBgContainer: getCssProperty('--surface-bg-color'),
        colorBorder: getCssProperty('--select-border-color'),
        colorPrimaryHover: getCssProperty('--bg-color-secondary'),
        optionSelectedBg: getCssProperty('--bg-color-secondary'),
        optionActiveBg: getCssProperty('--bg-color-secondary'),
        colorTextPlaceholder: getCssProperty('--text-color-inactive'),
        colorText: getCssProperty('--text-color'),
        colorTextDisabled: getCssProperty('--text-color-inactive'),
        colorBgElevated: getCssProperty('--drawer-bg-color'),
      },
      Tabs: {
        colorText: getCssProperty('--text-color-caption'),
        colorBorderSecondary: getCssProperty('--header-card-border:'),
      },
      Drawer: {
        colorBgElevated: getCssProperty('--surface-bg-color'),
        colorText: getCssProperty('--text-color-muted'),
        colorBgMask: getCssProperty('--drawer-bg-mask'),
      },
      Input: {
        colorBgContainer: getCssProperty('--surface-bg-color'),
        activeBg: getCssProperty('--surface-bg-color'),
        colorText: getCssProperty('--text-color-muted'),
        colorTextPlaceholder: getCssProperty('--text-color-caption'),
        colorBorder: getCssProperty('--border-input'),
        colorTextDisabled: getCssProperty('--text-color-inactive'),
      },
      DatePicker: {
        colorBgContainer: getCssProperty('--surface-bg-color'),
        colorText: getCssProperty('--text-color-muted'),
        colorTextPlaceholder: getCssProperty('--text-color-caption'),
        colorBorder: getCssProperty('--border-input'),
        colorBgElevated: getCssProperty('--picker-bg-color'),
        cellHoverBg: getCssProperty('--bg-color-secondary'),
        controlItemBgActive: getCssProperty('--bg-color-secondary'),
        colorTextDisabled: getCssProperty('--text-color-inactive'),
        colorIcon: getCssProperty('--text-color-inactive'),
      },
      Dropdown: {
        colorBgElevated: getCssProperty('--drawer-bg-color'),
        controlItemBgHover: getCssProperty('--bg-color-secondary'),
        controlItemBgActive: getCssProperty('--bg-color-secondary'),
        controlItemBgActiveHover: getCssProperty('--bg-color-secondary'),
      },
      Modal: {
        contentBg: getCssProperty('--modal-bg-color'),
        colorBgMask: getCssProperty('--modal-mask-bg-color'),
      },
      Button: {
        defaultHoverBg: 'transparent',
        defaultHoverBorderColor: getCssProperty('--link-color'),
        defaultHoverColor: getCssProperty('--link-color'),
        colorTextDisabled: getCssProperty('--text-color-inactive'),
        borderColorDisabled: getCssProperty('--text-color-inactive'),
        defaultBg: 'transparent',
        defaultActiveBg: 'transparent',
        colorPrimary: getCssProperty('--button-primary-bg-color'),
      },
      Pagination: {
        itemActiveBg: 'transparent',
        colorTextDisabled: getCssProperty('--text-color-caption'),
        colorBgTextHover: getCssProperty('--pagination-hover-bg-color'),
      },
      Radio: {
        colorBgContainer: 'transparent',
        colorTextDisabled: getCssProperty('--text-color-inactive'),
      },
      Upload: {
        controlItemBgHover: getCssProperty('--bg-color-secondary'),
        colorTextDescription: getCssProperty('--text-color-muted'),
      },
      Popover: {
        colorBgElevated: getCssProperty('--drawer-bg-color'),
      },
      Alert: {
        colorErrorBg: '#2C1618',
        colorErrorBorder: '#5B2526',
        colorInfoBg: '#111A2C',
        colorInfoBorder: '#15325B',
        colorSuccessBg: '#162312',
        colorSuccessBorder: '#274916',
        colorWarningBg: '#2B2111',
        colorWarningBorder: '#594214',
      },
      InputNumber: {
        colorBgContainer: 'transparent',
        colorTextDescription: getCssProperty('--text-color-muted'),
      },
      Checkbox: {
        colorTextDisabled: getCssProperty('--text-color-inactive'),
        colorBorder: getCssProperty('--text-color-muted'),
      },
      Image: {
        colorBgMask: getCssProperty('--modal-mask-bg-color'),
      },
      Carousel: {
        colorText: getCssProperty('--carousel-text-color'),
      },
      Tag: {
        defaultBg: getCssProperty('--select-border-color'),
        colorBorder: getCssProperty('--select-border-color'),
      },
    },
    token: {
      fontWeightStrong: 700,
      controlItemBgHover: getCssProperty('--primary-color-muted'),
      colorPrimary: getCssProperty('--primary-color'),
      colorPrimaryHover: getCssProperty('--primary-color__hover'),
      colorLinkHover: getCssProperty('--primary-color__hover'),
      colorPrimaryActive: getCssProperty('--primary-color-active'),
      colorText: getCssProperty('--text-color'),
      colorInfo: getCssProperty('--primary-color'),
      colorBgMask: getCssProperty('--surface-bg-color'),
      fontFamily:
        "'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    },
  }
}
